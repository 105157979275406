import React, { useEffect } from "react";

import etg from "../../../assets/img/brands/etg.png";
import kynoch from "../../../assets/img/brands/kynoch.png";
import falcon from "../../../assets/img/brands/falcon.png";
import oemff from "../../../assets/img/brands/oemff.png";
import "./Brands.css";
import { Link } from "react-router-dom";
import { useListAllBrandQuery } from "../../products/productSlice";
import axios from "axios";
import { AiFillCar } from "react-icons/ai";
import { RiArrowGoForwardLine } from "react-icons/ri";
import { FaMoneyBill } from "react-icons/fa";
import { BsShieldLockFill } from "react-icons/bs";

const imgs = [etg, kynoch, falcon, oemff];

function Brands() {
  // const { data, isLoading, error } = useListAllBrandQuery()

  return (
    <>
      <section className="brandsSec">
        <div className="subscribeSec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="subsText">
                  <h4>Subscribe Now !</h4>
                  <p>Venam By Signing Up To Our Newsletter.</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="subsForm">
                  <input
                    type="text"
                    placeholder="Enter your Email..."
                    className="form-control"
                  />
                  <button className="btn subscribeBtn">subscribe</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <div className="keyInfo">
                <div className="icon">
                  <AiFillCar />
                </div>
                <div className="content">
                  <h6>Free Shipping</h6>
                  <p>
                    Free same day delivery within Doha on orders above 200 QAR.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="keyInfo">
                <div className="icon">
                  <RiArrowGoForwardLine />
                </div>
                <div className="content">
                  <h6>Full Refund</h6>
                  <p>
                    Didn't like it?. No problem! simply return it within 7 days
                    and get a full refund.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="keyInfo">
                <div className="icon">
                  <FaMoneyBill />
                </div>
                <div className="content">
                  <h6>Debit & Credit Card</h6>
                  <p>
                    Don't have a Credit Card? We accept any debit card issued in
                    Qatar.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="keyInfo">
                <div className="icon">
                  <BsShieldLockFill />
                </div>
                <div className="content">
                  <h6>100% Secure Payment</h6>
                  <p>
                    We ensure 100% secure payment through accredited 3rd party.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Brands;
