import React from "react";

// import a1 from '../../../assets/img/leatzmeat/about-chicken.png'
import { TiTick } from "react-icons/ti";
function AboutStory({ data, t }) {
  return (
    <>
      <div className="aboutSection pt-4 pb-4">
        <div className="container-fluid">
          <h3 className="subtitle">{t("OUR STORY")}</h3>
          <p className="">{data?.about_company}</p>
          <p>{t("about-1")}</p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque,
            doloremque similique laborum cumque sed earum? Eligendi perferendis
            saepe reprehenderit placeat ab non omnis, est temporibus nemo
            numquam quibusdam illo necessitatibus! Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Libero facilis quisquam unde, tempore,
            blanditiis culpa inventore impedit dignissimos laborum dolorum
            molestiae quas minima. Ea incidunt voluptatem perspiciatis suscipit,
            ab officiis.
          </p>
        </div>
      </div>
    </>
  );
}

export default AboutStory;
