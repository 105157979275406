import React from "react";

const PromotionCard = ({ item }) => {
  return (
    <>
      <div className="col-md-3">
        <div className="promCard">
          <div className="figure">
            <img src={item.url} alt={item.title} className="img-fluid" />
          </div>
          <div className="content">
            <div className="date">
              <strong>{item.daysLeft}</strong>
              <span>days left</span>
            </div>
            <div className="text">
              <h6>{item.title}</h6>
              <p>{item.date}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromotionCard;
