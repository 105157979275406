import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import QuiekViewModal from "../../../../pages/QueikViewModal/QuiekViewModal";
import axios from "axios";
import { useTranslation } from "react-i18next";
import BestDealsProduct from "./best-deal-product/BestDealsProduct";
import { base_url } from "../../../../server";

function BestDealsProducts() {
  const [data, setData] = useState();
  const [error, seterror] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const baseUrl = base_url();
  const getdata = async (page) => {
    setisLoading(true);
    try {
      // const res = await axios.get(`https://onlineparttimejobs.in/api/product/page/${page}&${12}`, { withCredentials: true })
      const res = await axios.get(`${baseUrl}product/page/0&10000`, {
        withCredentials: true,
      });
      setData(res.data);
      setisLoading(false);
    } catch (error) {
      alert("Server Error !");
      seterror(true);
      setisLoading(false);
    }
  };
  useEffect(() => {
    getdata(0);
  }, []);

  const [modelDataId, setModelDataId] = useState(null);
  const setProduct_id = (id) => {
    setModelDataId(id);
  };

  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setModelDataId(id);
    setShow(true);
  };

  const sortedData = useMemo(() => {
    return data?.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });
  }, [data]);

  return (
    <>
      <section className="productsSection pt-0">
        <div className="container-fluid">
          <div className="row featuredRow">
            
            <BestDealsProduct
              data={sortedData}
              getFeaturedPro={getdata}
              isLoading={isLoading}
              error={error}
              handleShow={handleShow}
              setProduct_id={setProduct_id}
            />
          </div>
        </div>

        {/* {modelDataId && (
          <QuiekViewModal modelDataId={modelDataId} show={show} onHide={handleClose} size="xl"
            centered />
        )} */}
      </section>
    </>
  );
}

export default BestDealsProducts;
