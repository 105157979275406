import { Table } from "react-bootstrap";

function SentRefundRequest() {
  return (
    <div className="container-fluid">
      <div
        className="row card"
        style={{ padding: "10px", margin: "10px auto" }}
      >
        <h4>Applied Refund Request</h4>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Order Id</th>
              <th>Product</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
              <td>@mdo</td>
              <td>@mdo</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
}
export default SentRefundRequest;
