import React from "react";
import img1 from "../../../assets/img/promotion_main_image-2.jpg.webp"
import img2 from "../../../assets/img/promo-08-img-big.jpg.webp"
import img3 from "../../../assets/img/promotion_main_image-5.jpg.webp"
import img4 from "../../../assets/img/promotion_main_image-4.jpg.webp"
import PromotionCard from "./promotion-card/PromotionCard";
const data = [
  {
    id: "1",
    title: "Racing card + 2-year license for races k1",
    date: "to 12/31/2024",
    daysLeft: "114",
    url: img1
  },
  {
    id: "2",
    title: "Only today you can buy a camera with 20% discount ",
    date: "to 12/31/2024",
    daysLeft: "11",
    url: img2
  },
  {
    id: "3",
    title: "5% discount for X-Box One & Sabertooth bundle ",
    date: "to 12/31/2024",
    daysLeft: "86",
    url: img3
  },
  {
    id: "4",
    title: "Free shipping for orders over $ 50.",
    date: "to 12/31/2024",
    daysLeft: "55",
    url: img4
  }
]
const Promotions = () => {
  return (
    <>
      <section className="promotionSec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="fisherman-content">
                <h3 className="text-white">Explore current promotions </h3>
              </div>
            </div>
            
              {data.map((item)=>{
                return <PromotionCard key={item.id} item={item} />
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Promotions;
