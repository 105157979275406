import React from "react";
import { Link } from "react-router-dom";
import banner7 from "../../../assets/img/home-living.webp";
import banner8 from "../../../assets/img/it.webp";
import banner9 from "../../../assets/img/securiy.webp";
import banner10 from "../../../assets/img/entertainment1.webp";
const salesOffers = [
  {
    id: 1,
    title: "NEW PRODUCT",
    details: "HOME AUTOMATION",
    para: "Personalize your home automation. Personalize your app.It's simple.",
    url: banner7,
  },

  {
    id: 2,
    title: "BIG SALE",
    details: "SECURITY",
    para: "Bullet Camera and Simulated Cameras from top brands",
    url: banner8,
  },

  {
    id: 3,
    title: "WEEKEND DEAL",
    details: "IT PRODUCTS",
    para: "Shop Insight for IT products & services including laptops, computers,",
    url: banner9,
  },

  {
    id: 4,
    title: "ENTERTAINMENT",
    details: "ENTERTAINMENT",
    para: "Market products like High Powered experience unmatched anywhere else",
    url: banner10,
  },
];

const BigSale = () => {
  return (
    <>
      <section className="bigSale">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="fisherman-content">
                <h3>Make Your Home smarter & better</h3>
                <p>
                  we provides home and office automation products like Wifi
                  Light Switch, Touch Switches, Hotel Automation Switches,
                  Curtain Motor, Tubular Motor and Rolling Shutter Motor.
                </p>
              </div>
            </div>
            {salesOffers.map((item) => {
              return (
                <div className="col-md-3" key={item.id}>
                  <div className="devSale">
                    <img
                      src={item.url}
                      alt={item.details}
                      className="img-fluid"
                    />
                    <div className="devSaleContent">
                      <span>{item.title}</span>
                      <h4>{item.details}</h4>
                      <p>{item.para}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default BigSale;
