import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import headphone from "../../../assets/img/banner/slide2-headphone.jpg";
import laptop from "../../../assets/img/banner/slide2-laptop.jpg";
import phone from "../../../assets/img/banner/slide2-phone.jpg";
import animation_morning_multimedia from "../../../assets/img/47-abt__ut2_background_mp4_video-banner.mp4";

import "./Banner.css";
import { Link } from "react-router-dom";
import { useGetBannerQuery } from "./bannerSlice";
import axios from "axios";
import { bannerDB } from "../../../rki-data/banner";
import { isMobile } from "react-device-detect";
import { base_url } from "../../../server";
const bannerData = bannerDB;
function Banner() {
  const [data, setData] = useState();
  const baseUrl = base_url();

  var settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}banner/public`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (error) {
      alert("Server Error BannerList");
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <section className="bannerSection">
        {/* <Slider {...settings}>
            {data &&
              data.map((item) => {
                return (
                  item?.image?.url && (
                    <Link to={item?.url || ""} key={item._id}>
                      <div
                        className="bannerItem"
                        style={!isMobile ? { height: "500px" } : {}}
                      >
                        <img
                          src={item?.image.url}
                          className="aspect-ratio-img"
                        />
                      </div>
                    </Link>
                  )
                );
              })}
          </Slider> */}

        <div className="videoBanner">
          <video
            src={animation_morning_multimedia}
            muted
            loop
            autoPlay
            width={"100%"}
            height={"100%"}
          ></video>

          <div className="text">
            <h1>Get Your Home Better by One Click</h1>{" "}
            <Link to={"/"}>click here</Link>
          </div>
        </div>

        {/* <Slider {...settings}>
          <div className="bannerInfo">
            <img
              src={headphone}
              alt="Banner"
              title="MAQ the world Electronics"
            />
            <div className="bannerText">
              <span className="flash">weekend deal</span>
              <h2>Discount 50% on All Headphones</h2>
              <p>Free shipping over $100</p>

              <p className="price">
                price <strong>$499.99</strong>
              </p>
              <Link to={"/"}>shop now</Link>
            </div>
          </div>
          <div className="bannerInfo">
            <img src={laptop} alt="Banner" title="MAQ the world Electronics" />
            <div className="bannerText">
              <span className="flash">big sale</span>
              <h2>The Best Deal on MackBooks</h2>
              <p>about 250% off</p>

              <p className="price">
                price <strong>$499.99</strong>
              </p>
              <Link to={"/"}>shop now</Link>
            </div>
          </div>
          <div className="bannerInfo">
            <img src={phone} alt="Banner" title="MAQ the world Electronics" />
            <div className="bannerText">
              <span className="flash">flash sale</span>
              <h2>Top Selling Smartphone and accessories</h2>
              <p>discount of up to 40%</p>

              <p className="price">
                price <strong>$499.99</strong>
              </p>
              <Link to={"/"}>shop now</Link>
            </div>
          </div>
        </Slider> */}
      </section>
    </>
  );
}

export default Banner;
