import React from 'react'

const SmartItem = ({item}) => {
  return (
    <>
      <div className="col-md-4">
        <div className="smartInfo">
          <div className="smartFigure">
            <img src={item.url} alt={item.name} className='img-fluid' />
            <div className="smartText">
              <span>{item.category}</span>
              <h5>{item.name}</h5>
              <p>Discount Price</p>
              <h4>{item.discountedPrice} <span>{item.originalPrice}</span></h4>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SmartItem