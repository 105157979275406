import React from "react";
import { Link } from "react-router-dom";

const CountDown = () => {
  return (
    <>
      <div className="countdown">
        <h3>
          Black Friday Deals: Enjoy Incredible Savings and Unbeatable Offers All
          Month Long!
        </h3>
        <p>
          It’s Black Friday all month long with new deals each wee. Get an
          advance look at all the deals.
        </p>

        <span>Promotion expires within:</span>

        <div className="js-counter">
          <ul>
            <li className="counter">
              <span>32</span> days
            </li>
            <li className="counter">
              <span>15</span> hours
            </li>
            <li className="counter">
              <span>11</span> minutes
            </li>
            <li className="counter">
              <span>2</span> seconds
            </li>
          </ul>
        </div>

        <div className="moreLink">
          <Link to="/" className="more">
            More
          </Link>
          <Link to="/" className="all">
            all Promotions
          </Link>
        </div>
      </div>
    </>
  );
};

export default CountDown;
