import React from "react";

function Careers() {
  return (
    <>
      <section className="careersSec p-30">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="careersInfo">
                <div className="careersTitle mb-3">
                  <h1>Work With Us</h1>
                  <strong>
                    Let's do things together! Are you up for it? Let us know!
                    Join etg, by sending us your updated Curriculum Vitae. We
                    ensure our employees have just as many memorable moments as
                    our guests.
                  </strong>
                </div>

                <div className="careersNote mb-2">
                  <p>
                    Join Us, by sending your updated Curriculum Vitae at :
                    careers@etg.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Careers;
