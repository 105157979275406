import React from "react";

function DeliveryPolicy() {
  return (
    <>
      <section className="deliverySec p-30">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="deliveryInfo">
                <div className="deliveryTitle mb-3">
                  <h1>Delivery Policy</h1>
                </div>
                <div className="panel-body">
                  <p>
                    R K International ("we" and "us") is the operator of
                    (http://rki66.com) ("Website"). By placing an order through
                    this Website you will be agreeing to the terms below. These
                    are provided to ensure both parties are aware of and agree
                    upon this arrangement to mutually protect and set
                    expectations on our service.
                  </p>

                  <strong>1. General</strong>
                  <p>
                    Subject to stock availability. We try to maintain accurate
                    stock counts on our website but from time-to-time there may
                    be a stock discrepancy and we will not be able to fulfill
                    all your items at time of purchase. In this instance, we
                    will fulfill the available products to you, and contact you
                    about whether you would prefer to await restocking of the
                    backordered item or if you would prefer for us to process a
                    refund.
                  </p>

                  <strong>2. Shipping Costs</strong>
                  <p>
                    Shipping costs are calculated during checkout based on
                    weight, dimensions and destination of the items in the
                    order. Payment for shipping will be collected with the
                    purchase. This price will be the final price for shipping
                    cost to the customer.
                  </p>

                  <strong>3. Returns</strong>
                  <em>3.1 Return Due To Change Of Mind</em>
                  <p>
                    R K International will happily accept returns due to change
                    of mind as long as a request to return is received by us
                    within 1-3 days of receipt of item and are returned to us in
                    original packaging, unused and in resellable condition.
                    Return shipping will be paid at the customers expense and
                    will be required to arrange their own shipping. Once returns
                    are received and accepted, refunds will be processed to
                    store credit for a future purchase. We will notify you once
                    this has been completed through email. (R K International)
                    will refund the value of the goods returned but will NOT
                    refund the value of any shipping paid.
                  </p>

                  <strong></strong>
                  <em>3.2 Warranty Returns</em>
                  <p>
                    R K International will happily honor any valid warranty
                    claims, provided a claim is submitted within 90 days of
                    receipt of items. Customers will be required to pre-pay the
                    return shipping, however we will reimburse you upon
                    successful warranty claim. Upon return receipt of items for
                    warranty claim, you can expect R K International to process
                    your warranty claim within 7 days. Once warranty claim is
                    confirmed, you will receive the choice of:
                  </p>
                  <ul>
                    <li>(a) refund to your payment method</li>
                    <li>(b) a refund in store credit</li>
                    <li>
                      (c) a replacement item sent to you (if stock is available)
                    </li>
                  </ul>

                  <strong>4. Delivery Terms</strong>
                  <em>4.1 Transit Time Domestically</em>
                  <p>
                    In general, domestic shipments are in transit for 2 - 7 days
                  </p>

                  <strong>4.2 Transit time Internationally</strong>
                  <p>
                    Generally, orders shipped internationally are in transit for
                    4 - 22 days. This varies greatly depending on the courier
                    you have selected. We are able to offer a more specific
                    estimate when you are choosing your courier at checkout.
                  </p>

                  <strong>4.3 Dispatch Time</strong>
                  <p>
                    Orders are usually dispatched within 2 business days of
                    payment of order Our warehouse operates on Monday - Friday
                    during standard business hours, except on national holidays
                    at which time the warehouse will be closed. In these
                    instances, we take steps to ensure shipment delays will be
                    kept to a minimum.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DeliveryPolicy;
