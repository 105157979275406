import React from "react";

function SellerTestimonialUser() {
  return (
    <>
      <section className="testimonialUser p-30">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="tesTimonialUserInfo">
                <div className="tesTimonialUserImg">
                  <img
                    src="https://img1a.flixcart.com/fk-sp-static/images/Chaitanya_Ramalingegowda__DirectorCo-founder_Wakefit.png"
                    alt="Seller"
                    className="img-fluid"
                  />
                </div>
                <div className="tesTimonialUserText">
                  <h6>Chaitanya Ramalingegowda, Wakefit.co</h6>
                  <p>
                    "BBD and BDS sales compounded the consumer demand which led
                    to a milestone of 150% YoY revenue growth from ETG"
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="tesTimonialUserInfo">
                <div className="tesTimonialUserImg">
                  <img
                    src="https://img1a.flixcart.com/fk-sp-static/images/Raju.png"
                    alt="Seller"
                    className="img-fluid"
                  />
                </div>
                <div className="tesTimonialUserText">
                  <h6>Raju Lunawath, Amazestore</h6>
                  <p>
                    "Starting with 1, ETG helped me expand to 6 categories with
                    5x growth year on year!"
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="tesTimonialUserInfo">
                <div className="tesTimonialUserImg">
                  <img
                    src="https://img1a.flixcart.com/fk-sp-static/images/Shubhash_Chopra.png"
                    alt="Seller"
                    className="img-fluid"
                  />
                </div>
                <div className="tesTimonialUserText">
                  <h6>Shubhash Chopra, DivisaStore</h6>
                  <p>
                    "With ETG, we went from Rs.20,000 to Rs.2 Cr in sales per
                    annum!"
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SellerTestimonialUser;
