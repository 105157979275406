import React from "react";
import { Link } from "react-router-dom";
import banner12 from "../../../assets/img/banner-12.jpg";

const AllGames = () => {
  return (
    <>
      <section className="allGames">
          <div
            className="gamesCard"
            style={{
              backgroundImage: `url(${banner12})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="gamesCardText">
              <span>EXCLUSIVE GAMES</span>
              <h2>Discounts 50% On All Games </h2>
              <Link to={"/"}>shop now</Link>
            </div>
          </div>
      </section>
    </>
  );
};

export default AllGames;
