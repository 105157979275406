import React from "react";
import SmartItem from "./smart-item/SmartItem";
import banner32 from "../../../assets/img/banner-32.jpg";
import banner33 from "../../../assets/img/banner-33.jpg";
import banner31 from "../../../assets/img/banner-31.jpg";
const data = [
  {
    id: "1",
    category: "Smartphone",
    name: "Samsung Note 21",
    discountedPrice: 390,
    url: banner32,
    originalPrice: 490,
  },
  {
    id: "2",
    category: "Power Bank",
    name: "New Power Double",
    discountedPrice: 190,
    originalPrice: 290,
    url: banner33,
  },
  {
    id: "3",
    category: "Headphone",
    name: "Harman Cardon Ulty",
    discountedPrice: 390,
    originalPrice: 390,
    url: banner31,
  },
];
const SmartItems = () => {
  return (
    <>
      <section className="smartItems">
        <div className="container-fluid">
          <div className="row">
            {data.map((item) => {
              return <SmartItem key={item.id} item={item} />;
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default SmartItems;
